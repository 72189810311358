<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新增'}服务`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="服务类型" prop="categoryKey">
        <select-maintain
          v-model="form.data.categoryKey"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BNSOPRTCTGY']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="客户名称" prop="customer.id">
        <select-maintain
          v-model="form.data.customer"
          :ajax="{ action: 'GET /enocloud/common/customer/query', params: (params, name) => (params.payload = { name, rsexp: 'id,name' }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="联系人手机">
        <en-input :value="form.data.customer?.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="车牌号">
        <select-maintain
          v-model="form.data.vehicle"
          :ajax="{
            action: 'GET /enocloud/common/vehicle/query',
            params: (params, plateNo) => (params.payload = { plateNo, customerId: form.data.customer?.id })
          }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="车品牌">
        <en-input :value="form.data.vehicle?.vehicleSpec?.join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="到期日期">
        <en-date-picker v-model="form.data.dueDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="服务备注">
        <en-input v-model="form.data.comment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.data.status?.code === 'S'" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudBusinessOpportunityDefinitions['BusinessOpportunityDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'categoryKey.code': [{ required: true, message: '请选择服务类型' }],
          'customer.id': [{ required: true, message: '请选择客户名称' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/:opportunityId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/business/opportunity',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/business/opportunity',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
